<template>
  <div class="progress-status" :class="iconPosition">
    <div :class="status.value">{{ $t(status.label) }}</div>
    <component :is="computedIcon" />
  </div>
</template>

<script>
import ProgressDoing from '@/components/Icons/ProgressDoing.vue';
import ProgressDone from '@/components/Icons/ProgressDone.vue';
import ProgressNotStarted from '@/components/Icons/ProgressNotStarted.vue';
import ProgressUnavailable from '@/components/Icons/ProgressUnavailable.vue';
import ProgressRejected from '@/components/Icons/ProgressRejected.vue';

export default {
  name: 'ProgressStatus',
  components: {
    ProgressUnavailable,
    ProgressNotStarted,
    ProgressDone,
    ProgressDoing,
    ProgressRejected,
  },
  props: {
    iconPosition: {
      type: String,
      default: 'right',
    },
    status: {
      type: Object,
      default: () => ({
        value: 'not-started',
        label: 'label.passed',
      }),
    },
  },
  computed: {
    computedIcon() {
      if (this.status.value === 'done') {
        return ProgressDone;
      }
      if (this.status.value === 'not-started') {
        return ProgressNotStarted;
      }
      if (this.status.value === 'unavailable') {
        return ProgressUnavailable;
      }

      if (this.status.value === 'rejected') {
        return ProgressRejected;
      }

      if (this.status.value === 'doing') {
        return ProgressDoing;
      }

      if (this.status.value === 'blocked') {
        return ProgressUnavailable;
      }
      return ProgressDoing;
    },
  },
};
</script>

<!-- eslint-disable max-len -->
<template>
  <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.75 1H22.875C23.7038 1 24.4987 1.37226 25.0847 2.03489C25.6708 2.69752 26 3.59623 26 4.53333V29.2667C26 30.2038 25.6708 31.1025 25.0847 31.7651C24.4987 32.4277 23.7038 32.8 22.875 32.8H4.125C3.2962 32.8 2.50134 32.4277 1.91529 31.7651C1.32924 31.1025 1 30.2038 1 29.2667V4.53333C1 3.59623 1.32924 2.69752 1.91529 2.03489C2.50134 1.37226 3.2962 1 4.125 1H7.25"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.875 1H10.125C9.50368 1 9 1.49435 9 2.10417V4.3125C9 4.92231 9.50368 5.41667 10.125 5.41667H16.875C17.4963 5.41667 18 4.92231 18 4.3125V2.10417C18 1.49435 17.4963 1 16.875 1Z"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M23 12H17.5H12" stroke="#FAA433" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M9 10L5.5625 13L4 11.6364"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M23 19H12" stroke="#FAA433" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M9 17L5.5625 20L4 18.6364"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M23 26H12" stroke="#FAA433" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M9 24L5.5625 27L4 25.6364"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'QuizIcon',
};
</script>

<!-- eslint-disable max-len -->
<template>
  <svg width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.384 1H3.616C2.17122 1 1 2.56163 1 4.488V29.512C1 31.4384 2.17122 33 3.616 33H22.384C23.8288 33 25 31.4384 25 29.512V4.488C25 2.56163 23.8288 1 22.384 1Z"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="13" cy="17" r="8" stroke="#FAA433" />
    <path d="M17 17L11 20.4641L11 13.5359L17 17Z" fill="#FAA433" />
  </svg>
</template>

<script>
export default {
  name: 'VideoIcon',
};
</script>

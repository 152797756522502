<!-- eslint-disable max-len -->
<template>
  <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.2222 1H3.77778C2.24365 1 1 2.59188 1 4.55556V29.4444C1 31.4081 2.24365 33 3.77778 33H23.2222C24.7563 33 26 31.4081 26 29.4444V4.55556C26 2.59188 24.7563 1 23.2222 1Z"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line x1="8.5" y1="7.5" x2="8.5" y2="27.5" stroke="#FAA433" stroke-linecap="round" />
    <line x1="13.5" y1="13.5" x2="13.5" y2="27.5" stroke="#FAA433" stroke-linecap="round" />
    <line x1="18.5" y1="17.5" x2="18.5" y2="27.5" stroke="#FAA433" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'PptxIcon',
};
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM8 12.1701L14.59 5.58008L16 7.00008L8 15.0001L4 11.0001L5.41 9.59008L8 12.1701Z"
      fill="#1CE890"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProgressDone',
};
</script>

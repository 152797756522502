<template>
  <div
    class="lms-checkbox"
    :class="{
      'check-box': isTypeCheckBox,
      'pointer-events-none': isSending || isQuestionCompleted,
      'lms-checkbox--small': size === 'sm',
    }"
  >
    <input
      type="checkbox"
      :checked="checked"
      :id="this._uid"
      @click="$emit('change')"
      :disabled="checked && !isTypeCheckBox"
    />
    <label :for="this._uid"></label>
  </div>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    isSending: {
      type: Boolean,
      default: false,
    },
    isQuestionCompleted: {
      type: Boolean,
      default: false,
    },
    isTypeCheckBox: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
  },
};
</script>

<!-- eslint-disable max-len -->
<template>
  <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6246 1H4.12492C3.29614 1 2.5013 1.33714 1.91527 1.93726C1.32923 2.53737 1 3.35131 1 4.2V29.8C1 30.6487 1.32923 31.4626 1.91527 32.0627C2.5013 32.6629 3.29614 33 4.12492 33H22.8744C23.7032 33 24.4981 32.6629 25.0841 32.0627C25.6701 31.4626 25.9994 30.6487 25.9994 29.8V10.6L16.6246 1Z"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.625 1V10.6H25.9998"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.7497 18.5996H7.25"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M19.7497 25H7.25" stroke="#FAA433" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M10.3749 12.1992H8.81246H7.25"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'DocxIcon',
};
</script>

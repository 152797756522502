import {
  pptx, pdf, docx, zip,
} from '@/utils/constants';

export default {
  data: () => ({
    pptx,
    pdf,
    docx,
    zip,
  }),
};

<template>
  <!-- eslint-disable max-len -->
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.05213 0.203766C8.38806 0.135189 8.72832 0.0837325 9.07165 0.049919L9.16844 1.04453L9.26531 2.03983C9.45905 2.02097 9.65511 2.00899 9.85327 2.00418C7.8224 2.05372 5.97918 2.86011 4.59495 4.152C4.71894 4.03626 4.84661 3.92442 4.97775 3.81667L4.34293 3.04402L3.70826 2.27157C4.217 1.85404 4.76325 1.4898 5.33824 1.18249L5.80948 2.06229L6.28164 2.94381C6.73938 2.69863 7.22337 2.49693 7.72821 2.34398L7.43825 1.38694L7.14906 0.432413C7.44589 0.342356 7.74723 0.266008 8.05213 0.203766ZM4.24202 4.5024C4.11213 4.63957 3.98705 4.78132 3.86704 4.92739L3.09438 4.29256L2.32193 3.6579C1.90441 4.16663 1.54017 4.71289 1.23286 5.28788L2.11265 5.75912L2.99417 6.23127C2.74899 6.68902 2.54729 7.173 2.39434 7.67785L1.4373 7.38789L0.482777 7.0987C0.293496 7.72256 0.164786 8.36634 0.100282 9.02128L1.09489 9.11808L2.09019 9.21494C2.06993 9.42307 2.05761 9.63387 2.05359 9.84708C2.09283 7.77804 2.91756 5.90122 4.24202 4.5024ZM2.05273 10.1014C2.05555 10.3334 2.06819 10.5626 2.09019 10.7886L1.09489 10.8855L0.100282 10.9822C0.164786 11.6372 0.293496 12.281 0.482776 12.9048L1.4373 12.6156L2.39434 12.3257C2.54729 12.8305 2.74899 13.3145 2.99417 13.7723L2.11265 14.2444L1.23285 14.7157C1.54017 15.2906 1.9044 15.8369 2.32193 16.3456L3.09438 15.711L3.86704 15.0761C3.9887 15.2242 4.11558 15.3679 4.24738 15.5068C2.90803 14.095 2.07828 12.195 2.05273 10.1014ZM4.97775 16.1869C4.82973 16.0652 4.68613 15.9384 4.54725 15.8066C5.96708 17.1535 7.88064 17.985 9.98828 18.0015C9.74406 17.9996 9.50294 17.9868 9.2653 17.9637L9.16844 18.959L9.07164 19.9536C8.72832 19.9198 8.38806 19.8683 8.05213 19.7998C7.74722 19.7375 7.44589 19.6612 7.14906 19.5711L7.43825 18.6166L7.72821 17.6596C7.22337 17.5066 6.73938 17.3049 6.28163 17.0597L5.80948 17.9412L5.33824 18.821C4.76325 18.5137 4.217 18.1495 3.70826 17.732L4.34292 16.9595L4.97775 16.1869Z"
      fill="#FFC438"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C9.63739 18 9.28042 17.9759 8.9306 17.9291L8.66339 19.9103C6.25196 19.585 4.04186 18.3917 2.44714 16.5539C0.852411 14.716 -0.0175137 12.3598 0.000339138 9.92663C0.018192 7.49344 0.922598 5.15023 2.54412 3.33601C4.16564 1.52178 6.39301 0.361022 8.80895 0.0711919L9.04708 2.05616C9.35955 2.01908 9.67755 2 10 2C14.4183 2 18 5.58172 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
      fill="#FFC438"
    />
    <line x1="9" y1="6" x2="9" y2="12" stroke="#FFC438" stroke-width="2" />
    <line x1="15" y1="11" x2="8" y2="11" stroke="#FFC438" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: 'ProgressDoing',
};
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM14.1758 4.41431L15.5858 5.82431L11.41 10.0001L15.5858 14.1759L14.1758 15.5859L9.99997 11.4101L5.82418 15.5859L4.41418 14.1759L8.58997 10.0001L4.41418 5.82431L5.82418 4.41431L9.99997 8.59009L14.1758 4.41431Z"
      fill="#999999"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProgressUnavailable',
};
</script>
